import oucy from "@/util/oucyUtil";
import { notice, stock, schemeOrder, furnitureGlobalBrand } from "@/service/index"
import { localSet, localGet, localDel } from "@/store/store"

export default {
    name: "Enter",
    data() {
        return {
            obj: {},
            pageWidth: oucy.pageWidth,
            schemeId: null,
            // 供货方
            // 供货方
            // 供货方
            rulesSeller: {
                sellerAvata: [
                    { required: true, message: '请选择头像', trigger: 'change' }
                ],
                sellerQrcode: [
                    { required: true, message: '请选择二维码', trigger: 'change' }
                ],
                sellerName: [
                    { required: true, message: '请输入供货方名称', trigger: 'blur' }
                ],
                sellerRealName: [
                    { required: true, message: '请输入联系人', trigger: 'blur' }
                ],
                sellerPhone: [
                    { required: true, message: '请输入联系电话', trigger: 'blur' }
                ],
                sellerCompany: [
                    { required: true, message: '请输入公司门店', trigger: 'blur' }
                ],
                sellerPosition: [
                    { required: true, message: '请输入职务', trigger: 'blur' }
                ],
                sellerEmail: [
                    { required: true, message: '请输入邮箱', trigger: 'blur' }
                ],
                sellerAddr: [
                    { required: true, message: '请输入详细地址', trigger: 'blur' }
                ],
            },
            ruleSeller: {
                sellerAvata: '',
                sellerName: '',
                sellerRealName: '',
                sellerPhone: '',
                sellerCompany: '',
                sellerCompany: '',
                sellerPosition: '',
                sellerEmail: '',
                sellerQrcode: '',
                sellerAddr: '',
                sellerDefault: false,
            },
            // 弹框
            dialogVisibleAddSeller: !true,
            dialogVisibleSetSeller: !true,
            objSeller: {},


            // 采购方
            // 采购方
            // 采购方
            ruleBuyer: {
                buyerContactName: '',
                buyerName: '',
                buyerContactPhone: '',
            },

            rulesBuyer: {
                buyerName: [
                    { required: true, message: '请输入供货方名称', trigger: 'blur' }
                ],
                buyerContactName: [
                    { required: true, message: '请输入联系人', trigger: 'blur' }
                ],
                buyerContactPhone: [
                    { required: true, message: '请输入联系电话', trigger: 'blur' }
                ],

            },
            // 弹框
            dialogVisibleAddBuyer: !true,
            dialogVisibleSetBuyer: !true,
            objBuyer: {},


            // 品牌
            dialogVisibleSetBrands: !true,
            UserBrands: [],
            objBrand: {},
            objBrandCache: {},


            value: '选项1',
            checked: 1,
            radio: 1,
            num: 1,
            dialogVisible2: true,
            dialogFormVisible: !true,
            dialogBuyerVisible: !true,
            text: '',
            textarea: '',
            list: [],
            obj: {},
            TheSelectedItems: 0,
            // checkAll: [0,0],
            // isIndeterminate: true
            // 调整 类型
            // 将总优惠金额按折扣分配至每件单品
            AdjustPriseWithTotalPrise: {
                adjustPriseWithType: 'adjustPriseWithTotalPrise',
                distribution: false,
                totalPrise: null,
                discount: null,
                reducePrice: null,
            },
            queryContactSellerObj: {
                limit: 10,
                start: 0,
            },
            queryContactBuyerObj: {
                limit: 10,
                start: 0,
            },
            prlductData: {
                content: []
            },
            contactSeller: {},
            contactBuyer: {},
            num: 0,

        }
    },
    computed: {},
    mounted() {
        let enterprise = localGet('enterprise')
        if (enterprise && enterprise.id) {
            this.enterpriseId = enterprise.id
        }
        this.schemeId = this.$route.query.schemeId
        this.getBySchemeId()
    },
    methods: {

        // 根据scheme编码查询进货车方案详细信息
        getBySchemeId() {
            stock.getBySchemeId({ schemeId: this.schemeId }).then(res => {
                let list = []
                let num = 0
                if (res && res.stockEnterprises) {
                    for (let v of res.stockEnterprises) {
                        num += v.stockCount
                        list.push(...v.stockItems || [])
                    }
                }
                this.num = num
                this.obj = res
                this.list = list

                // 000品牌图标         
                this.objBrand.brandIcon = res.materialBrandAvata
                // 000品牌名称         
                // 000采购方名称         
                this.objBuyer.id = true
                this.objBuyer.buyerContactName = res.materialCustomerName
                // 000采购方电话         
                this.objBuyer.buyerContactPhone = res.materialCustomerPhone
                // 000采购方姓名         
                this.objBuyer.buyerName = res.materialCustomerRealName

                // 000供货方详细地址          
                this.objSeller.id = true
                this.objSeller.sellerAddr = res.materialSellerAddr
                // 000供货方头像            
                this.objSeller.sellerAvata = res.materialSellerAvata
                // 000供货方公司名称          
                this.objSeller.sellerName = res.materialSellerCompany
                // 000供货方公司代码          
                // 000供货方联系人邮箱         
                this.objSeller.sellerEmail = res.materialSellerEmail
                // 000供货方联系人手机号码           
                this.objSeller.sellerPhone = res.materialSellerPhone
                // 000供货方联系人职务         
                this.objSeller.sellerPosition = res.materialSellerPosition
                // 000供货方二维码名片         
                this.objSeller.sellerQrcode = res.materialSellerQrcode
                // 000供货方联系人姓名         
                this.objSeller.sellerRealName = res.materialSellerRealName

            })

        },
        print() {
            window.print()

        },

        // 上传回调
        uploadImageBack0(v) {
            this.objBrand = {}
            this.objBrand.brandIcon = v
        },
        uploadImageBack(v) {
            this.ruleSeller.sellerAvata = v
        },
        uploadImageBack2(v) {
            this.ruleSeller.sellerQrcode = v
        },

        handleClose(done) {
            this.$confirm('确认关闭？')
                .then(_ => {
                    done();
                })
                .catch(_ => {});
        },
        // 供货方
        // 供货方
        // 供货方
        // 点确定
        submitForm() {
            this.$refs['ruleSeller'].validate((valid) => {
                if (valid) {
                    if (this.ruleSeller.id) {
                        this.updateContactSeller()
                    } else {
                        this.createContactSeller()
                    }
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        // 添加供货方
        createContactSeller() {
            notice.createContactSeller(this.ruleSeller).then(res => {
                this.dialogVisibleAddSeller = false
            })
        },
        openAddSeller() {
            this.ruleSeller = {
                sellerAvata: '',
                sellerName: '',
                sellerRealName: '',
                sellerPhone: '',
                sellerCompany: '',
                sellerCompany: '',
                sellerPosition: '',
                sellerEmail: '',
                sellerQrcode: '',
                sellerAddr: '',
                sellerDefault: false,
            }
            this.dialogVisibleAddSeller = true
        },

        // 点 选择
        setSeller(v) {
            this.objSeller = v
            this.dialogVisibleSetSeller = !true
        },
        openSetSeller() {
            this.getAllContactSeller()
            this.dialogVisibleSetSeller = true
        },
        // 点修改
        updateSeller(v, i) {
            this.ruleSeller = Object.assign({}, v)
            // this.dialogVisibleSetSeller = !true
            this.dialogVisibleAddSeller = true
        },
        // 修改
        updateContactSeller() {
            notice.updateContactSeller(this.ruleSeller).then(res => {
                this.dialogVisibleAddSeller = false
                this.getAllContactSeller()
            })
        },
        // 删除
        delContactSeller(v, i) {
            notice.delContactSeller(v).then(res => {
                this.prlductData.content.splice(i, 1)
                    --this.prlductData.totalElements
                // 如果删除的是当前选中的 
                if (v.id == this.objSeller.id) {
                    this.objSeller = {}
                }
            })
        },

        sizeChangeSeller: function(limit) {
            const me = this;
            this.queryContactSellerObj.limit = limit;
            me.getAllContactSeller();
        },
        currentChangeSeller: function(current) {
            const me = this;
            this.queryContactSellerObj.start = current - 1;
            me.getAllContactSeller();
        },
        // 查询供货方
        getAllContactSeller() {
            notice.getAllContactSeller(this.queryContactSellerObj).then(res => {
                this.prlductData = res || {}
            })
        },



        // 采购方
        // 采购方
        // 采购方
        // 采购方
        // 
        // 添加采购方
        createContactBuyer() {
            notice.createContactBuyer(this.ruleBuyer).then(res => {

                this.dialogVisibleAddBuyer = !true
            })
        },
        // 编辑采购方
        updateContactBuyer() {
            notice.updateContactBuyer(this.ruleBuyer).then(res => {

                this.dialogVisibleAddBuyer = !true
            })
        },
        openAddBuyer() {
            this.ruleBuyer = {
                buyerContactName: '',
                buyerName: '',
                buyerContactPhone: '',
            }
            this.dialogVisibleAddBuyer = true
        },
        // 点修改
        updateBuyer(v) {
            this.ruleBuyer = Object.assign({}, v)
            this.dialogVisibleAddBuyer = true
        },
        openSetBuyer() {
            this.getAllContactBuyer()
            this.dialogVisibleSetBuyer = true
        },
        // 删除
        delContactBuyer(v, i) {
            notice.delContactBuyer(v).then(res => {
                this.ruleBuyer.content.splice(i, 1)
                    --this.ruleBuyer.totalElements
                // 如果删除的是当前选中的 
                if (v.id == this.objBuyer.id) {
                    this.objBuyer = {}
                }
            })
        },

        sizeChange: function(limit) {
            const me = this;
            this.queryContactBuyerObj.limit = limit;
            me.getAllContactBuyer();
        },
        currentChange: function(current) {
            const me = this;
            this.queryContactBuyerObj.start = current - 1;
            me.getAllContactBuyer();
        },

        // 查询采购方
        getAllContactBuyer() {
            notice.getAllContactBuyer(this.queryContactBuyerObj).then(res => {
                this.contactBuyer = res || {}

            })
        },
        // 选择采购方
        setBuyer(v) {
            this.objBuyer = v
            this.dialogVisibleSetBuyer = false
        },

        // 品牌

        getUserBrands() {
            furnitureGlobalBrand.getUserBrands({ enterpriseId: this.enterpriseId }).then(res => {
                this.UserBrands = res
            })
        },
        openSetBrand() {
            this.getUserBrands()
            this.dialogVisibleSetBrands = true
        },
        hasImportImg(v) {
            return v && v.startsWith('//')
        },
        submitForm2() {
            this.$refs['ruleBuyer'].validate((valid) => {
                if (valid) {
                    if (this.ruleBuyer.id) {
                        this.updateContactBuyer()
                    } else {

                        this.createContactBuyer()
                    }
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        submit() {

            if (!this.objBrand.brandIcon) {
                this.alert('请设置品牌图片')
                return
            }

            if (!this.objSeller.id) {
                this.alert('请设置供货方')
                return
            }

            if (!this.objBuyer.id) {
                this.alert('请设置采购方')
                return
            }
            let data = {
                // 000方案编码         
                schemeId: this.schemeId,
                // 000品牌图标         
                materialBrandAvata: this.objBrand.brandIcon,
                // 000品牌名称         
                materialBrandName: null,
                // 000采购方名称         
                materialCustomerName: this.objBuyer.buyerContactName,
                // 000采购方电话         
                materialCustomerPhone: this.objBuyer.buyerContactPhone,
                // 000采购方姓名         
                materialCustomerRealName: this.objBuyer.buyerName,

                // 000供货方详细地址          
                materialSellerAddr: this.objSeller.sellerAddr,
                // 000供货方头像            
                materialSellerAvata: this.objSeller.sellerAvata,
                // 000供货方公司名称          
                materialSellerCompany: this.objSeller.sellerName,
                // 000供货方公司代码          
                materialSellerCompanyCode: null,
                // 000供货方联系人邮箱         
                materialSellerEmail: this.objSeller.sellerEmail,
                // 000供货方联系人手机号码           
                materialSellerPhone: this.objSeller.sellerPhone,
                // 000供货方联系人职务         
                materialSellerPosition: this.objSeller.sellerPosition,
                // 000供货方二维码名片         
                materialSellerQrcode: this.objSeller.sellerQrcode,
                // 000供货方联系人姓名         
                materialSellerRealName: this.objSeller.sellerRealName,
            }
            stock.setupSchemaMaterial(data).then(res => {
                console.log(res)
                this.$oucy.go('/printResult?schemeId=' + this.schemeId)
            })
        },
        alert(text, title) {
            this.$alert(text, title || '提示', {
                confirmButtonText: '确定',
            });
        },

        submitForm3(v) {
            if (this.objBrandCache && this.objBrandCache.id) {
                this.objBrand = this.objBrandCache
                this.dialogVisibleSetBrands = false
            } else {
                this.alert('请选择品牌')
            }
        },
        exportExcel() {
            this.$oucy.downloadRequest('/client/product/notice/exportExcel', { schemeId: this.schemeId }).then(res => {
                let a = document.createElement("a");
                a.download = `${this.obj.schemeName}_${new Date().toLocaleString()}.xlsx`;
                a.href = window.URL.createObjectURL(new Blob([res.data]));
                a.click();
            })
        },
    }
}