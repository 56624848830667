<template>
    <div class="text-left">
        <div class="container">
            <div class="text-center p-t-30">
                <h2>【{{obj.schemeName}}】报价清单</h2>
            </div>
            <div class="pageCenter">
                <el-container>
                    <div style="padding: 0 0 50px;width: 100%">
                        <!--                        <div class="title c-1 f24 m-t-30">{{obj.schemeName}}</div>-->
                        <div class="box m-t-30">
                            <div class="left">
                                <div style="width:150px">
                                    <upload-image @uploadImageBack="uploadImageBack0" class="avatar-uploader" size="mini">
                                        <img v-if="objBrand.brandIcon" :src="$oucy.ossUrl+objBrand.brandIcon" class="avatar mini">
                                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                                    </upload-image>
                                    <!-- <el-button type="text" size="mini" @click="openSetBrand">选择已有品牌图片</el-button> -->
                                </div>
                            </div>
                            <div class="center">
                                <div class="f14 c-2">
                                    <div class="df m-t-10">
                                        <div class="l_left ">采购方</div>
                                        <div class="l_right line-1">
                                            <span class="c-1" v-if="objBuyer.buyerContactName">{{objBuyer.buyerContactName}}</span>
                                            <span v-else class="c-2">请先添加采购方信息</span>
                                        </div>
                                    </div>
                                    <div class="df m-t-10">
                                        <div class="l_left ">联系人</div>
                                        <div class="l_right line-1">
                                            <span class="c-1" v-if="objBuyer.buyerName">{{objBuyer.buyerName}}</span>
                                            <span v-else class="c-2">请先添加采购方信息</span>
                                        </div>
                                    </div>
                                    <div class="df m-t-10">
                                        <div class="l_left ">联系电话</div>
                                        <div class="l_right line-1">
                                            <span class="c-1" v-if="objBuyer.buyerContactPhone">{{objBuyer.buyerContactPhone}}</span>
                                            <span v-else class="c-2">请先添加采购方信息</span>
                                        </div>
                                    </div>
                                    <!--               <div class="df m-t-10">
                          <el-button type="primary" size="mini" class="m-l-30" @click="openAddBuyer">添加采购方</el-button>
                          <el-button type="primary" size="mini" @click="openSetBuyer">选择已有</el-button>
                      </div> -->
                                </div>
                            </div>
                            <div class="right">
                                <div class="f14 c-2">
                                    <div class="df m-t-10">
                                        <div class="l_left ">供货方</div>
                                        <div class="l_right line-1">
                                            <span class="c-1" v-if="objSeller.sellerName">{{objSeller.sellerName}}</span>
                                            <span v-else class="c-2">请先添加供货方信息</span>
                                        </div>
                                    </div>
                                    <div class="df m-t-10">
                                        <div class="l_left ">联系人</div>
                                        <div class="l_right line-1">
                                            <span class="c-1" v-if="objSeller.sellerRealName">{{objSeller.sellerRealName}}</span>
                                            <span v-else class="c-2">请先添加供货方信息</span>
                                        </div>
                                    </div>
                                    <div class="df m-t-10">
                                        <div class="l_left ">联系电话</div>
                                        <div class="l_right line-1">
                                            <span class="c-1" v-if="objSeller.sellerPhone">{{objSeller.sellerPhone}}</span>
                                            <span v-else class="c-2">请先添加供货方信息</span>
                                        </div>
                                    </div>
                                    <!-- <div class="df m-t-10">
                                        <el-button type="primary" size="mini" class="m-l-30" @click="openAddSeller">添加供货方</el-button>
                                        <el-button type="primary" size="mini" @click="openSetSeller">选择已有供货方</el-button>
                                    </div> -->
                                </div>
                            </div>
                        </div>
                        <!-- list:{{list}} -->
                        <template>
                            <el-table :data="list" border style="width: 100%" class="m-t-20">
                                <el-table-column type="index" width="50px" label="序号" style="text-align: center"></el-table-column>
                                <el-table-column prop="date" label="产品图片" width="100px">
                                    <template slot-scope="scope">
                                        <!-- <video v-if="scope.row.spuVideo" :src="$oucy.ossUrl+scope.row.spuVideo" style="width:40px;height: 40px;background: #f1f1f1;" controls="1"></video> -->
                                        <!-- <div v-else> -->
                                        <el-avatar shape="square" :src="scope.row.itemAvata" v-if="hasImportImg(scope.row.itemAvata)"></el-avatar>
                                        <el-avatar shape="square" :src="$oucy.ossUrl+scope.row.itemAvata" v-else></el-avatar>
                                        <!-- </div> -->
                                    </template>
                                </el-table-column>
                                <el-table-column prop="itemName" label="产品名称"></el-table-column>
                                <el-table-column prop="itemAttrDesc" label="产品规格">
                                </el-table-column>
                                <el-table-column prop="itemCount" label="数量" width="80px" style="align-content: center">
                                </el-table-column>
                                <el-table-column prop="spuPrise" label="价格" width="120px">
                                    <template slot-scope="scope">
                                        <span class="spuPrise" v-if="scope.row.itemAdjustPrise=='0.00'">面议</span>
                                        <span class="spuPrise" v-else>￥{{scope.row.itemAdjustPrise}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="isShelves" label="备注" v-if="0">
                                    <template slot-scope="scope">
                                        <div>2021-06-16 00:00:00</div>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </template>
                        <div class="df f14 c-1 m-t-20">
                            <div class="m-r-20">产品数量：<span class="blod">{{num}}</span></div>
                            <div class="m-r-30">报价清单总额(RMB)：<span class="blod">{{obj.schemePrise}} 元</span></div>
                            <div class="m-r-30">实收金额(RMB)：<span class="blod">{{obj.schemeAdjustPrise}} 元</span></div>
                            <div>大写金额(RMB)：<span class="blod">{{$oucy.ToString(obj.schemeAdjustPrise)}}</span></div>
                        </div>
                        <div class="text-center m-t-50 p-b-50 print">
                            <el-button type="primary" @click="print()">打印预览</el-button>
                            <el-button type="" @click="$oucy.back()">返回</el-button>
                        </div>
                    </div>
                </el-container>
            </div>
        </div>
    </div>
</template>
<script>
import result from './result.js'
export default result
</script>
<style lang="less" scoped>
@import "result.less";
</style>